import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

import logo from '@app/assets/images/logo.png';

const Hakkimizda: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.AboutUs',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 pt-20 pb-5 lg:px-0 xl:max-w-container-xl text-lg">
        <img className="m-auto" src={logo} width={300} />

        <h1 className="mt-20 mb-5 font-bold text-center">Hakkımızda</h1>

        <div className="who-am-i-description">
          <p className="mb-4 w-80 m-auto text-center mb-20">
            Hairmedicareturkey Plastik ve Estetik Cerrah Dr Serdar Bora
            Bayraktaroğlu ve Anestezi ve Reanimasyon Uzmanı Dr Merih Tombul
            Yeşildal tarafından kurulan saç ekimi ve trikolojik saç tedavileri
            yapılan bir merkezdir .Kliniğimizde uzman doktor eşliğinde
            konsültasyonunuz ve saç tedaviniz yapılır.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Hakkimizda;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
